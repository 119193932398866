// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agota-award-js": () => import("/opt/build/repo/src/pages/agota-award.js" /* webpackChunkName: "component---src-pages-agota-award-js" */),
  "component---src-pages-award-details-js": () => import("/opt/build/repo/src/pages/award-details.js" /* webpackChunkName: "component---src-pages-award-details-js" */),
  "component---src-pages-denes-award-js": () => import("/opt/build/repo/src/pages/denes-award.js" /* webpackChunkName: "component---src-pages-denes-award-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/opt/build/repo/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-what-we-do-js": () => import("/opt/build/repo/src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("/opt/build/repo/src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */)
}

